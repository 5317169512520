var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md-layout"},_vm._l((_vm.tab_data),function(panel,p_index){return _c('div',{key:p_index,staticClass:"md-layout-item md-xsmall-size-100",class:`md-medium-size-${panel.width}`},[_c('div',{staticClass:"md-layout"},_vm._l((panel.items),function(item,i_index){return _c('div',{key:i_index,staticClass:"md-layout-item md-xsmall-size-100",class:`md-medium-size-${item.width} md-size-${item.width}`},[(item.type==='card')?[_c('stats-card',{attrs:{"header-color":item.content.color}},[_c('template',{slot:"header"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v(_vm._s(item.content.icon))])],1),_c('p',{staticClass:"category"},[_vm._v(_vm._s(_vm.$t(item.content.text)))]),_c('h3',{staticClass:"title"},[_c('animated-number',{attrs:{"value":item.content.value}})],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"stats"},[_vm._v(" "+_vm._s(_vm.$t(item['sub-text']))+" ")])])],2)]:(item.type==='chart')?[_c('chart-card',{ref:"chartcard",refInFor:true,attrs:{"background-color":item.content.color,"chart-data":{
              labels: item.content.data.labels.map((label) =>  _vm.$t(label)),
              series: item.content.data.series.map((ser) => {
                return Array.isArray(ser)
                  ? ser.map((el) => {
                      return {
                        ...el,
                        meta: _vm.tranformMeta(el.meta)
                      }
                    })
                  : {
                      ...ser,
                      meta: _vm.tranformMeta(ser.meta)
                    }
              })
            },"chart-options":{
              ...item.content.options,
              labelInterpolationFnc: function (value) {
                return value === 0 ? '' : value;
              },
              height: item.content.height? item.content.height:'300px',
              plugins: [
                _vm.$Chartist.plugins.tooltip({
                  tooltipOffset: {x: -10, y: -22},
                  anchorToPoint: false,
                  currency:true,
                  tooltipFnc: function(meta, value) {
                    const oMeta = JSON.parse(meta)
                    return `${_vm.$t(oMeta.data.title)}: ${value}`
                  }
                }),
                _vm.$Chartist.plugins.ctAxisTitle({
                  axisX: {
                    axisTitle: _vm.getAxisXTitle(item.content.options),
                    axisClass: 'ct-axis-title',
                    offset: {
                      x: 0,
                      y: 33
                    },
                    textAnchor: 'middle'
                  },
                  axisY: {
                    axisTitle: _vm.getAxisYTitle(item.content.options),
                    axisClass: 'ct-axis-title',
                    offset: {
                      x: 0,
                      y: 11
                    },
                    textAnchor: 'middle',
                    flipTitle: true
                  }
                })
              ]
            },"chart-type":item.content.type.charAt(0).toUpperCase() + item.content.type.slice(1),"distributeSeries":true,"no-footer":item.content.hasFooter==100000,"chart-inside-content":"","header-animation":"false","header-icon":""}},[_c('template',{slot:"chartInsideHeader"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v(_vm._s(item.content.icon))])],1),_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(item.content.title))+" ")])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"md-layout"},[(item.content.options.legend.title)?_c('div',{staticClass:"md-layout-item md-size-100"},[_c('h6',{staticClass:"category"},[_vm._v(_vm._s(_vm.$t('graph.legend')))])]):_vm._e(),(item.content.options.legend.items)?_vm._l((item.content.options.legend.items),function(elem){return _c('div',{key:elem.name,staticClass:"md-layout-item legend-label md-medium-size-30 md-small-size-100"},[_c('i',{class:elem.icon_class,style:({color:elem.color})}),_vm._v(" "+_vm._s(_vm.$t(elem.name))+" ")])}):_vm._e()],2)])],2)]:_vm._e()],2)}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }